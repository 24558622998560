import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import * as swal from 'sweetalert2';
import { Tooltip } from 'bootstrap';

// Connects to data-controller="resource-updates"
export default class extends Controller {

  static targets = [ 'addField', 'addItem', 'disableItem', 'dragDrop', 'field', 'items', 'removeField', 'removeItem', 'saveButton', 'selectAll' ];
  static values = {
    fieldsIds: Array,
    updateUrl: String
  };

  connect() {
    this.valuesUpdated = false;
    this.hook = false;

    // Use event delegation to capture changes to dynamically added inputs
    this.element.addEventListener( 'change', ( event ) => {
      if( event.target.matches( '#resource_values input' ) ){
        this.valuesUpdated = true;
      }
    } );

    this.enableHook();
    this.updateTable();
  }

  change() {
    if( !this.hook ){
      this.hook = true;
    }
  }

  save( event ) {
    this.hook = false;
    event.currentTarget.blur();
  }

  enableHook() {
    window.onbeforeunload = () => {
      if( this.hook ){
        return 'Changes that you made may not be saved.';
      }
    };
  }

  end( event ) {
    this.updatePositions( event.target );
    this.updateFields();
  }

  dragDropTargetConnected() {
    this.sortable = Sortable.create( this.dragDropTarget, {
      handle: '.enable-drag',
      onEnd: this.end.bind( this )
    } );
  }

  itemsTargetConnected() {
    this.updateTable();
  }

  selectAllTargetConnected() {
    this.updateCheckboxesFromFieldsIds();
  }

  toggleField( event ) {
    const checkbox = event.target;
    const fieldId = parseInt( checkbox.value );

    let fieldIds = this.fieldsIdsValue.slice();

    if( checkbox.checked ){
      fieldIds = fieldIds.filter( id => id !== fieldId );
    } else {
      fieldIds.push( fieldId );
    }

    this.fieldsIdsValue = fieldIds;
    this.updateSelectAllCheckbox();
  }

  toggleSelectAll() {
    const allChecked = this.selectAllTarget.checked;
    let fieldIds = [];

    this.fieldTargets.forEach( checkbox => {
      checkbox.checked = allChecked;
      if( !allChecked ){
        fieldIds.push( parseInt( checkbox.value ) );
      }
    } );

    this.fieldsIdsValue = fieldIds;
    this.updateSelectAllCheckbox();
  }

  updateCheckboxesFromFieldsIds() {
    const fieldIds = this.fieldsIdsValue;

    this.fieldTargets.forEach( checkbox => {
      const checkboxValue = parseInt( checkbox.value );
      checkbox.checked = !fieldIds.includes( checkboxValue );
    } );

    this.updateSelectAllCheckbox();
  }

  updateSelectAllCheckbox() {
    const totalFields = this.fieldTargets.length;
    const checkedFields = this.fieldTargets.filter( checkbox => checkbox.checked ).length;

    if( checkedFields === totalFields ){
      this.selectAllTarget.checked = true;
      this.selectAllTarget.indeterminate = false;
    } else if( checkedFields === 0 ){
      this.selectAllTarget.checked = false;
      this.selectAllTarget.indeterminate = false;
    } else {
      this.selectAllTarget.checked = false;
      this.selectAllTarget.indeterminate = true;
    }
    this.updateColumnVisibility();
  }

  getFieldsIds() {
    return this.fieldsIdsValue;
  }

  updateColumnVisibility() {
    const fieldIds = this.getFieldsIds();

    // Show all fields
    this.fieldTargets.forEach( checkbox => {
      const fieldId = checkbox.value;
      const divToHide = $( `div[data-field-id="${fieldId}"]` );
      if( divToHide ){
        divToHide.removeClass( 'hide-cell' );
      }
    } );

    // hide the fields that are unchecked
    fieldIds.forEach( fieldId => {
      const divToHide = $( `div[data-field-id="${fieldId}"]` );
      if( divToHide ){
        divToHide.addClass( 'hide-cell' );
      }
    } );
  }

  addField( event ) {
    event.preventDefault();

    swal.fire( {
      confirmButtonText: 'Create',
      input: 'text',
      inputValidator: ( value ) => {
        if( !value ){
          return 'You must supply a field name';
        }
        if( this.valuesUpdated ){
          return `Save the values before adding <strong>&nbsp;${value}&nbsp;</strong> field`;
        }
      },
      showCancelButton: true,
      title: 'Add a new field',
    } ).then( ( result ) => {
      if( result.value ) {
        const fieldName = result.value;
        const count = $( '#resource_fields' ).find( '.enable-drag' ).length;
        const newId = new Date().getTime();
        const row = $( '#field_element' ).text()
                                         .replace( /{i}/g, '' )
                                         .replace( /{name}/g, fieldName.toLowerCase() )
                                         .replace( /{position}/g, count + 1 )
                                         .replace( /{row_id}/g, newId );

        if ( count > 0 ) {
          $( '#resource_fields' ).find( '.enable-drag' ).last().after( row );
        } else {
          $( '#resource_fields' ).append( row );
        }
        $( '#add-items' ).removeClass( 'd-none' );
        this.updateFields();
      }
    } );
  }

  removeField( event ) {
    event.preventDefault();
    event.currentTarget.blur();

    const fieldName = event.currentTarget.closest( '.input-wrapper' ).querySelector( 'input' ).value;
    const $this = event.currentTarget;

    if( this.valuesUpdated ){
      window.toastr.error( 'Save the values before removing <strong>' + fieldName + '</strong> field' );
      return false;
    }

    swal.fire( {
      cancelButtonText: "Cancel",
      confirmButtonColor: "#d9534f",
      confirmButtonText: "Yes, delete it!",
      icon: "warning",
      preConfirm: () => {
        $this.closest( '.input-wrapper' ).remove();
        this.updateFields();
      },
      reverseButtons: true,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      title: 'Are you sure you want to delete the <strong>' + fieldName + '</strong> field?\n This action will automatically remove all values.',
    } );

    return false;
  }

  addItem( event ) {
    event.preventDefault();
    event.currentTarget.blur();

    const $tab = $( '#resource_values' );
    const new_id = ( new Date ).getTime();
    const row = $( '#value_row' ).text()
                                 .replace( /\{i}/g, '' )
                                 .replace( /\{row_id}/g, new_id );
    $tab.append( row );
    $tab.find( 'div.hidden:last' ).remove();
    $tab.find( 'div.d-table-row:last input:visible:first' ).trigger( 'focus' );
    $( '[data-bs-toggle="tooltip"]' ).tooltip();
    this.updateColumnVisibility();
  }

  removeItem( event ) {
    event.preventDefault();
    event.currentTarget.blur();

    // Dispose tooltip
    const tooltipElement = event.currentTarget;
    const tooltip = Tooltip.getInstance( tooltipElement );
    if( tooltip ){
      tooltip.dispose();
    }

    event.currentTarget.closest( 'div.d-table-row' ).remove();
    this.updateTable();

    this.valuesUpdated = true;
  }

  disableItem( event ) {
    const $row = event.currentTarget.closest( 'div.d-table-row' );

    if( event.currentTarget.checked ){
      $( $row ).removeClass( 'table-danger' );
    } else {
      $( $row ).addClass( 'table-danger' );
    }
  }

  async updateFields() {
    const form = $( '.edit_resource' );
    const csrfToken = document.querySelector( "meta[name='csrf-token']" ).content;

    this.updatePositions( form );

    const fields = form.serializeJSON();
    const url = this.element.dataset.updateUrlValue;

    if ( !$.isEmptyObject( fields ) ) {
      delete fields.resource.batches_attributes;
      delete fields.resource.name;
      delete fields.resource.url;

      try {
        const response = await fetch( url, {
          body: JSON.stringify( fields ),
          credentials: 'same-origin',
          headers: {
            'Accept': 'text/vnd.turbo-stream.html',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken,
          },
          method: 'PATCH',
        } );
        const data = await response.text();
        window.Turbo.renderStreamMessage( data );
      } catch ( error ) {
        window.toastr.error( 'Unknown error has occurred' );
        console.error( 'Error:', error );
      }
    }
  }

  updatePositions( element ) {
    $( element ).find( 'input[name^="resource[fields_attributes]"][name$="[position]"]' ).each( function( index ) {
      $( this ).val( index + 1 );
    } );
  }

  updateTable() {
    const tableBody = $( '.css-table-body' );
    const rows = tableBody.find( '.d-table-row' );
    let count = 0;

    if( !rows.length > 0 ) {
      tableBody.closest( '.css-table' ).addClass( 'border-bottom-0' );
    } else {
      tableBody.closest( '.css-table' ).removeClass( 'border-bottom-0' );
    }

    rows.each( function() {
      const row = $( this );
      row.removeClass( 'odd last' );

      if( !row.hasClass( 'hidden' ) ){
        count++;
        if( count % 2 === 1 ){
          row.addClass( 'odd' );
        }
      }
    } );
  }
}
